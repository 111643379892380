import request from '@/utils/request'


// 查询cover letter选项列表
export function listLetterOption(query) {
  return request({
    url: '/cover/letterOption/list',
    method: 'get',
    params: query
  })
}

// 查询cover letter选项分页
export function pageLetterOption(query) {
  return request({
    url: '/cover/letterOption/page',
    method: 'get',
    params: query
  })
}

// 查询cover letter选项详细
export function getLetterOption(data) {
  return request({
    url: '/cover/letterOption/detail',
    method: 'get',
    params: data
  })
}

// 新增cover letter选项
export function addLetterOption(data) {
  return request({
    url: '/cover/letterOption/add',
    method: 'post',
    data: data
  })
}

// 修改cover letter选项
export function updateLetterOption(data) {
  return request({
    url: '/cover/letterOption/edit',
    method: 'post',
    data: data
  })
}

// 删除cover letter选项
export function delLetterOption(data) {
  return request({
    url: '/cover/letterOption/delete',
    method: 'post',
    data: data
  })
}
